import axios from "axios"
import { authHeader, config } from '../config'

export const profileService = {
    updateProfile,
    updatePassword,
    getProfile
}

async function updateProfile (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/user/profile`, formdata, requestOptions)
    return response;
}

async function updatePassword (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/change-password`, formdata  ,requestOptions)
    return response;
}

async function getProfile (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/user/profile?id=${id}`, requestOptions)
    return response;
}

