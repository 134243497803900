import React, { useEffect } from "react";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecentOrders from "../components/RecentOrder";
import Header from "../components/Header";
import SideMenu from "../components/Sider";

import { Layout } from "antd";
import { dashboardActions,profilesActions } from "../actions";
import TransactionDetail from "../components/TransactionDetail";
import Spinner from '../components/Spinner'
// import { ordersActions } from "../actions/orderActions";

const { Content } = Layout;
const headerTitle = "Dashboard Overview";

const DashboardScreen = () => {
  const dispatch = useDispatch()
  const dashboard = useSelector(state=>state.dashboard)
  const profiles = useSelector(state=>state.profiles)
  const user = JSON.parse(sessionStorage.getItem('user'))


  useEffect(() => {
    dispatch(dashboardActions.getDashboard())
  }, [dispatch])

  useEffect(() => {
    dispatch(profilesActions.getProfile(user?.id))
     // eslint-disable-next-line
   }, [dispatch])
 
   let profileInfo = profiles?.profiles?.data?.data

  let dashboardData = dashboard?.dashboard?.data['0']

  return (
    <div>
      <Layout>
        <SideMenu className="media" />
        <Content className="dashboard-content">
          <Header titles={headerTitle} />
          {profileInfo && dashboardData ? (<div className="content-setting">
            <div className="dashboard-report pb-1 pt-2">
              <h2 className='capitalize'>Hi, {profileInfo?.full_name}</h2>
              <p>Here are the latest report on your account</p>
            </div>

            <div className="card-holder">
              <div>
                <Card className="card-n1 card">
                  <h2>{parseFloat(dashboardData?.total_delivery_amount?.replace(',','')).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NGN',
                  })}</h2>
                  <h4>Total Delivery Revenue</h4>
                </Card>
              </div>
              <div>
                <Card className="card-n2 card">
                  <h2>{dashboardData?.total}</h2>
                  <h4>Total Asssigned Orders </h4>
                </Card>
              </div>
              <div>
                <Card className="card-n3">
                  <h2>{dashboardData?.completed}</h2>
                  <h4>Successful Deliveries</h4>
                </Card>
              </div>
              <div>
                <Card className="card-n4">
                  <h2>{dashboardData?.cancelled}</h2>
                  <h4>Decline Deliveries</h4>
                </Card>
              </div>
            </div>
            <div className="transaction-card">
              <div>
                <Card className="card-trans">
                  <div className="trans-header">
                    <h3>Recent Transactions</h3>
                    <div className="trans-link">
                      <Link to="/rider/history">View All</Link>
                    </div>
                  </div>
                  <TransactionDetail />
                </Card>
              </div>
              <div className=" lg:mt-8">
                <Card className="card-trans">
                  <div className="trans-header">
                    <h3>Recent Orders</h3>
                    <div className="trans-link">
                      <Link to="/rider/route">View All</Link>
                    </div>
                  </div>
                  <RecentOrders />
                </Card>
              </div>
            </div>
          </div>) : (<Spinner />)}
        </Content>
      </Layout>
    </div>
  );
};

export default DashboardScreen;
