import React, {useState,useMemo} from "react";
import { Row, Col } from "antd";
import LoginImage from "../components/LoginImage";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import { authActions } from '../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spin from "../components/Loader";


function RecoverPassword1Screen(location) {
  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const dispatch = useDispatch()
  const history = useHistory()
  const [loading,setLoading] = useState(false)
  const [query, setQuery] = useState({
      email: '',
    })

  const disabled = useMemo(
  () =>  !query.email,
  [ query.email]
  );

  const handleInput = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  setQuery((prevState) => ({
      ...prevState,
      [name]: value
  }));
  };



  const submit = async (e) => {
      e.preventDefault();
      setLoading(true) 

      const formdata = new FormData();
        Object.entries(query).forEach(([key, value]) => {
        formdata.append(key, value);
          });
      
      const response =  await dispatch(authActions.sendPassword(formdata))
      if(response.data.status === true) {
          setLoading(false) 
          history.push(`/rider/recover-password2/${query.email}`)
        toast.success(response?.data?.message,{
          autoClose: 2000,
          hideProgressBar: true})
      }
      else {
          toast.error(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true })
            setLoading(false) 
          }

   }

  return (
    <div>
      <Row type="flex" className="login-page">
        <Col type="flex" span={12} className="login-screen">
          <LoginImage />
        </Col>
      
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          span={12}
          className=" pb-4 align-content"
        >
          <div className='min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen'>
                     
          <img
              width={150}
              alt=""
              className="pb-2 mt-2"
              src={`${process.env.REACT_APP_IMAGE}/primhex-logo.png`}
            />


             <h1 className='mt-12 text-xl lg:text-3xl font-bold'>
                 Recover Password
            </h1>

            <p className='text-gray-400'>
                    Fill the details below to recover your password 
            </p>


            <form className='mt-12' onSubmit={(e)=>submit(e)}>

                 <div>
                     <input type='text' placeholder = 'Email Address' className='login-text-box' value={query.email} name='email' onChange={(e)=>handleInput(e)} />
                </div>
                    

            
                <button type='submit' disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-56 w-full text-white py-1 rounded-sm font-bold justify-center' : 'mt-56 w-full bg-hex-blue text-white py-1 rounded-sm font-bold flex items-center justify-center'}>
                Send Intructions {loading &&  <Spin />}
                </button>

            </form> 
            </div>
          {/* <div className="login-form-wrapper">
            <img
              alt=""
              width={150}
              className="pb-2"
              src={`${process.env.PUBLIC_URL}img/primhex-logo.png`}
            />

            <h2 className="">Recover Password</h2>
            <h4>Fill the details below to recover your password</h4>

            <div className="pt-2 register-form">
              <div className="input-content">
                <Row className="mb-1">
                  <Col span={24} max-idth={800}>
                    <Form.Item
                      name="emailAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email Address!",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Email Address"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="add-pad">
                  <Link to="/recover-password2">
                    <Button
                      key="2"
                      block
                      className="submit-button"
                      htmlType="submit"
                    >
                      Send Instruction
                    </Button>
                  </Link>

                  <h4 className="text-center">
                    Don't have an account? &nbsp;
                    <Link
                      className="login-register-route"
                      to={
                        redirect
                          ? `/register?redirect=${redirect}`
                          : "/register"
                      }
                    >
                      Register for one now
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
        </Col>
      </Row>
    </div>
  );
}

export default RecoverPassword1Screen;
