import React from 'react'
import { Card, Select } from 'antd';
import SideMenu from '../components/Sider'
import Header from '../components/Header'
import Wallet from '../components/Wallet'
import { Layout } from 'antd';
import TransactionDetail from '../components/TransactionDetail';

const headerTitle = "History"
const { Option } = Select;

const { Content } = Layout;

function HistoryScreen() {
    function handleChange(value) {
  console.log(`selected ${value}`);
}
    return (
        <div>
            <Layout>
                    <SideMenu/>
                <Content style={{ marginLeft: 250 }} >
                    <Header titles={headerTitle} />
             <div className="content-setting">
            <div className="dashboard-report pb-1 pt-2">
                <h2>History</h2>
                <p >Here are the latest report on your account</p>
                        </div>
                        <Wallet />
            <div className="transaction-card">
                <div>
                <Card className="card-trans history-card" style={{ width: 1070 }}>
                                    <div className="trans-header transaction-history-header">
                                        <div className="history-header">
                                            <h3>Transactions History</h3>
                                            </div>
                        <div className="trans-link">
                            <Select defaultValue="Filter By" className="history-select" onChange={handleChange}>
                                                <Option value="startdate">Start Date</Option>
                                                <Option value="enddate">End Date</Option>
    </Select>
                        </div>
                    </div>
                    {/* <TransactionDetails /> */}
                    <TransactionDetail />
                    </Card>
                </div>
                </div>
                </div>
                </Content>
            </Layout>
        </div>
    )
}

export default HistoryScreen
