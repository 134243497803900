import { orderConstants,orderListConstants } from "../constants";


export const orders = (state = {}, action) => {
  switch (action.type) {
    case orderConstants.GET_ORDER_REQUEST:
      return {
        ...state
      }
    case orderConstants.GET_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.orders
      }
    case orderConstants.GET_ORDER_FAIL:
      return {
        ...state,
        error: action.error
      } 
      case orderConstants.GET_ACCEPTED_ORDER_REQUEST:
      return {
        ...state
      }
    case orderConstants.GET_ACCEPTED_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.orders
      }
    case orderConstants.GET_ACCEPTED_ORDER_FAIL:
      return {
        ...state,
        error: action.error
      } 
    case orderConstants.GET_COMPLETED_ORDER_REQUEST:
    return {
        ...state
    }
    case orderConstants.GET_COMPLETED_ORDER_SUCCESS:
    return {
        ...state,
        orders: action.orders
    }
    case orderConstants.GET_COMPLETED_ORDER_FAIL:
    return {
        ...state,
        error: action.error
    } 
    default:
      return state;
  }
};


export const ordersList = (state = {}, action) => {
  switch (action.type) {
    case orderListConstants.GET_PENDING_ORDER_REQUEST:
    return {
      ...state
    }
  case orderListConstants.GET_PENDING_ORDER_SUCCESS:
    return {
      ...state,
      ordersList: action.ordersList
    }
  case orderListConstants.GET_PENDING_ORDER_FAIL:
    return {
      ...state,
      error: action.error
    } 
    default:
    return state;
}
};