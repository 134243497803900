import React , { useState,useEffect,useMemo} from 'react'
// import { authActions } from '../../../actions/auth.actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import LoginImage from '../components/LoginImage';
import { Col,Row } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { authActions } from '../actions';
import { useHistory } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"


const RegisterRider = () => {
    const [show,setShow] = useState(0)
    const [image, setImage] = useState(null)
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState()
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const [banks,setBanks] = useState(null)
    const [vehicles,setVehicles] = useState(null)
    const [password,showPassword] = useState(false)
    const [file,setFile] = useState(null)
    const [view,setView] = useState(false)
    
    useEffect(() => {
            const fetchBanks = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v1/${process.env.REACT_APP_MERCHANT_NAME}/banks`)
            const bankList = await response.data.data
            setBanks(bankList)
            }
            fetchBanks()
    }, [])

    useEffect(() => {
        const fetchVehicles = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v1/${process.env.REACT_APP_MERCHANT_NAME}/vehicle-categories`)
        const list = await response.data.data
        setVehicles(list)
        }
        fetchVehicles()
}, [])

    const [query, setQuery] = useState({
        role_id:2,
        full_name: '',
        email: '',
        phone: '',
        home_address: '',
        profile_image:'',
        plate_no:'',
        account_number:'',
        password:'',
        bank_id:'',
        vehicle_category_id: '',
        dob:''
      })


    const [error,setError] = useState({
      email: false,
      phone: false
    })

    const [errors,setErrors] = useState({
      account_number: '',
      email: '',
      phone: '',
      password: ''
    })

    const disabled = useMemo(
      () =>  !query.email || !query.full_name || !query.home_address || !query.phone || !query.profile_image,
      [ query.email, query.full_name, query.home_address, query.phone , query.profile_image]
      );



    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const handleChange = async (e) => {
      setView(true)
   
      e.preventDefault();
      setFile(e.target.files[0]?.name)

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    
    }

    const getCropData = () => {
      if (typeof cropper !== "undefined") {
        setCropData(cropper.getCroppedCanvas().toDataURL());
      }
      let image = dataURLtoFile(cropper?.url,file)

      setQuery((prevState) => ({
            ...prevState,
            'profile_image': image
          }));

    };

    function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
          Object.entries(query).forEach(([key, value]) => {
          formdata.append(key, value);
            });

        const response =  await dispatch(authActions.register(formdata))
        console.log(response)
        if(response.data.status === true) {
            setLoading(false) 
            history.push('/rider')
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }

            if(response?.data?.data?.phone || response?.data?.data?.phone){
              setShow(!show);
            }

            setErrors(x=>({
              ...x,
              account_number: response?.data?.data?.account_number,
              email: response?.data?.data?.email,
              phone: response?.data?.data?.phone,
              password: response?.data?.data?.password
            }))

     }

     const changePage = (e) => {
      e.preventDefault();
      
      if(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(query?.email) && query?.phone?.length >= 11){
          console.log('email correct')
          setShow(!show);

          setError(x=>({
            ...x,
            email: false,
            phone: false
         }))

          
      }
      else if(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(query?.email) && query?.phone?.length <= 11){
        setError(x=>({
          ...x,
          email: false,
          phone: true
       }))
      }

      else if(!RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(query?.email) && query?.phone?.length >= 11){
        setError(x=>({
          ...x,
          email: true,
          phone: false
       }))
      }
      else{
        console.log('not correct')
        setError(x=>({
            ...x,
            email: true,
            phone: true
        }))

      }
    }


    return (
        <div className='max-h-screen'>
     <Row type="flex" className="login-page">
        <Col type="flex" span={12} className="login-screen">
          <LoginImage />
        </Col>

        {/* Login Page */}
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          span={12}
          className=" pb-4 align-content"
        >
          <div className="login-form-wrapper overflow-y-scroll h-screen">
            {/* Logo Icon */}
            <div className="company-details">
            <img
              width={100}
              alt=""
              className=""
              src={`${process.env.REACT_APP_IMAGE}/hex-logo-icon.png`}
            />
            <h3 className="login-logo">HEX LOGISTICS</h3>
            </div>
              <h2 className="">Create an Account</h2>
              <h4>Tell us about you</h4>

            <div className='w-full'>
               
               <div className='w-full flex flex-row justify-center'>
                <button onClick={()=>setShow(!show)} className={!show ? 'h-4 w-4 bg-hex-blue rounded-full mx-1' : 'h-4 w-4 bg-gray-200 rounded-full mx-1'}></button>
                <button onClick={()=>setShow(!show)} className={show ? 'h-4 w-4 bg-hex-blue rounded-full mx-1' : 'h-4 w-4 bg-gray-200 rounded-full mx-1'}></button>
               </div>
               
               <form onSubmit={(e)=>submit(e)}>
               {!show ? (<>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>Full Name<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='login-text-box' name='full_name' value={query.full_name} onChange={(e)=>handleInput(e)} />
                  </div>
               
                  <div>
                    <label className='font-bold'>Email Address<span className='text-red-700'>*</span></label>
                    <input type={'email'} className='login-text-box'  name='email' value={query.email} onChange={(e)=>handleInput(e)} />
                    
                    <p className='mt-1 text-xs text-red-500'>{error?.email && 'Please, kindly confirm e-mail!'}</p>
                    <p className='mt-1 text-xs text-red-500'>{errors?.email}</p>

                  </div>
               </div>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>Phone Number (xxxxxxxxxxx)<span className='text-red-700'>*</span></label>
                    <input type={'number'} className='login-text-box' name='phone' value={query.phone} onChange={(e)=>handleInput(e)} />

                    <p className='mt-1 text-xs text-red-500'>{error?.phone && 'Please, kindly confirm phone number!'}</p>
                    <p className='mt-1 text-xs text-red-500'>{errors?.phone}</p>

                  </div>
               
                  <div>
                    <label className='font-bold'>Address<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='login-text-box' name='home_address' value={query.home_address} onChange={(e)=>handleInput(e)}/>
                  </div>
               </div>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>
                  
                  <div>
                    <label className='font-bold'>Upload Profile Image<span className='text-red-700'>*</span></label>
                    <input type={'file'} className='file-btn mt-1 focuc:outline-none' name='profile_image' onChange={(e)=>handleChange(e)} accept="image/png, image/gif, image/jpeg"  />
                      {view && (<button type='button' onClick={()=>getCropData()} className='bg-gray-200 mt-1 p-2 rounded-md' >
                        Crop Image
                      </button>)}
                      
                     
                      
                  </div>

               </div>

                 {view &&( <div className='w-full grid grid-cols-2 gap-4 justify-between mt-1'>
                           
                           {cropData !== '#' && (<div>
                                                    <img className='w-32 rounded-md shadow text-xs' src={cropData} alt="cropped" />
                                                    <button onClick={()=>setView(false)} className='bg-hex-blue hover:bg-blue-800 text-xs text-white mt-1 p-2 rounded-md'>Select Image</button>
                                                  </div>
                           )}

                           

                          <Cropper
                              // style={{ height: 250, width: "100%" }}
                              zoomTo={0.5}
                              initialAspectRatio={1}
                              preview=".img-preview"
                              src={image}
                              viewMode={1}
                              minCropBoxHeight={10}
                              minCropBoxWidth={10}
                              background={false}
                              responsive={true}
                              autoCropArea={1}
                              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={true}
                            />

                      </div>)}
               
               

               <button type='submit' onClick={(e)=>changePage(e)}  disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-1 rounded-sm font-bold justify-center' : 'mt-12 w-full bg-hex-blue text-white rounded-sm font-bold flex items-center justify-center py-1'}>
               Continue
                  </button>
               </>): 
               (<>
                <h4 className="">Other Information</h4>
                <p>Let's get to know you better</p>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>
               <div>
                    <label className='font-bold'>Date of birth<span className='text-red-700'>*</span></label>
                    <input type={'date'} className='login-text-box' name='dob' value={query.dob} onChange={(e)=>handleInput(e)} />
                  </div>


               <div>
                 <label className='font-bold'>Account Number<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='login-text-box'  name='account_number' value={query.account_number} onChange={(e)=>handleInput(e)} />

                 <p className='mt-1 text-xs text-red-500'>{errors?.account_number}</p>

               </div>
               
               </div>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>

               <div>
                 <label className='font-bold'>Select Bank<span className='text-red-700'>*</span></label>
                 <select className='login-text-box' name='bank_id' value={query.bank_id} onChange={(e)=>handleInput(e)}>
                     <option>*Choose Bank</option>
                     {banks?.length !== 0 ? banks?.map((x)=>(
                        <option value={x.id} key={x.id}>{x.name}</option>
                     )) : ('')}
                 </select>
               </div>
               

               <div>
                 <label className='font-bold'>Select Vehicle Category<span className='text-red-700'>*</span></label>
                 <select className='login-text-box' name='vehicle_category_id' value={query.vehicle_category_id} onChange={(e)=>handleInput(e)}>
                     <option>*Choose Category</option>
                     {vehicles?.length !== 0 ? vehicles?.map((x)=>(
                        <option value={x.id} key={x.id}>{x.name}</option>
                     )) : ('')}
                 </select>
               </div>

               </div>
               
               <div className='mt-8 grid grid-cols-2 gap-24'>

                  <div>
                    <label className='font-bold'>Plate Number<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='login-text-box' name='plate_no' value={query.plate_no} onChange={(e)=>handleInput(e)} />
                  </div>

                  

                 <div> 
                  <div className='login-text-box flex bg-white flex-row item-center justify-between'>
                        <input type={password ? 'text' : 'password'} placeholder = 'Password' className='flex-1 outline-none' value={query.password} name='password' onChange={(e)=>handleInput(e)}/>
                        <button type='button' className='' onClick={()=>showPassword(!password)}>
                            {password ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                        </button>

                    </div>

                    <p className='mt-1 text-xs text-red-500'>{errors?.password}</p>

                    </div>

               </div>
               
               {/* {disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-4 rounded-sm font-bold justify-center' :  */}
               
               <button type='submit' className='mt-4 w-full bg-hex-blue text-white py-1 rounded-sm font-bold flex items-center justify-center'>
                        Submit {loading &&  <Loader />}
                </button>
               </>)
               }

            
               </form>
               <h4 className="text-center mt-1">
              Already have an account? &nbsp;
              <Link to="/rider" className="login-register-route">
                Login into your account
              </Link>
              </h4>
               </div>



          </div>
        </Col>
      </Row>
    </div>
    )
}

export default RegisterRider


