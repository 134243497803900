import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ordersActions } from '../actions/orderActions';


function RecentOrder() {
  const orders = useSelector(state=>state.orders)
  const dispatch = useDispatch()
  let orderList = orders?.orders?.data?.data?.slice(0,1)['0']

    useEffect(() => {
        dispatch(ordersActions.getSorterOrders('completed',5,1))
      }, [dispatch])

      console.log(orderList)
  return (
    <div>
      {orderList && (
      <div className="recent-order">
        <h3>
          Pickup - The <span>order with</span> ID:{orderList?.tracking_id} has been{" "}
          <label>Completed</label>{" "}
        </h3>
        <div className="main-content">

              <iframe 
                  width="500" 
                  height="500" 
                  frameborder="0" 
                  scrolling="no" 
                  marginheight="0" 
                  className='rounded-md'
                  marginwidth="0" 
                  src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDR7DFQIBGumoziD6B6a0n2EZgrKhQOWS4
                  &origin=${orderList?.sender_info?.pickup_lat},${orderList?.sender_info?.pickup_long}
              &destination=${orderList?.receiver_info?.destination_lat},${orderList?.receiver_info?.destination_long}`}
                  title='Google maps'
                  // src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${orderList?.receiver_info?.destination_lat},${orderList?.receiver_info?.destination_long}`}
                >
                </iframe>

          {/* <img
            alt=""
            width={500}
            className="pb-2"
            src={`${process.env.PUBLIC_URL}/img/map.png`}
          /> */}
        </div>
      </div>)}
    </div>
  );
}

export default RecentOrder;
