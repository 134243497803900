import { dashboardConstants } from "../constants"
import { dashboardService } from "../services/dashboard.services"

export const dashboardActions = {
    getDashboard
}

function getDashboard (formdata) {
    return async dispatch => {
        dispatch(request(formdata))

        try {
          const dashboard = await dashboardService.getDashboard(formdata)
          dispatch(success(dashboard))
          return dashboard;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request (formdata) { return { type: dashboardConstants.DASHBOARD_DETAILS_REQUEST, formdata } }
  function success (dashboard) { return { type: dashboardConstants.DASHBOARD_DETAILS_SUCCESS, dashboard } }
  function failure (error) { return { type: dashboardConstants.DASHBOARD_DETAILS_FAIL, error } }
}
