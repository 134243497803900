import React from "react";
import Header from "../components/Header";
import SideMenu from "../components/Sider";
import { Layout, Row, Col } from "antd";
import { Form, Input, Card, Button } from "antd";
import { PlusSquareFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

const headerTitle = "Send a Package";
const { Content } = Layout;

const CreateRoute = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Layout>
        <SideMenu />
        <Content style={{ marginLeft: 250 }}>
          <Header titles={headerTitle} />
          <div className="content-setting">
            <div className="dashboard-report pt-2 pb-1">
              <h2>Create a Route</h2>
              <p>Fill the form below to create a route</p>
            </div>

            <Card className="profile-container create-route">
              <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 14,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Route Name"
                      name="Route Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Adewale Salem"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <h4>Routes 1</h4>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Address"
                      name="Route Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Ikorodu, Lagos"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Date"
                      name="Route Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Select Date"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Link to="/">
                  <h6>
                    <PlusSquareFilled className="pr-0" />
                    Add another route
                  </h6>
                </Link>
                <Link to="/rider/edit-route">
                  <Button type="primary" className="profile-button">
                    Proceed
                  </Button>
                </Link>
              </Form>
            </Card>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default CreateRoute;
