import { authConstants } from "../constants"

export function auth (state = {isAuthenticated:null}, action ) {
    switch(action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loading: true
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                auth: action.auth,
                isAuthenticated: true
            }
        case authConstants.LOGIN_ERROR:
            return {
                error: action.error
            }
        case authConstants.LOGOUT:
            return {
                isAuthenticated: false
            }
        case authConstants.REGISTER_REQUEST:
        return {
            ...state
        }
        case authConstants.REGISTER_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authConstants.REGISTER_FAILURE:
        return {
            ...state,
            error: action.error
        }
        case authConstants.SEND_PASSWORD_REQUEST:
        return {
            ...state
        }
        case authConstants.SEND_PASSWORD_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authConstants.SEND_PASSWORD_FAILURE:
        return {
            ...state,
            error: action.error
        }
        case authConstants.UPDATE_PASSWORD_REQUEST:
        return {
            ...state
        }
        case authConstants.UPDATE_PASSWORD_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authConstants.UPDATE_PASSWORD_FAILURE:
        return {
            ...state,
            error: action.error
        }
        case authConstants.ADD_BUSINESS_REQUEST:
        return {
            ...state
        }
        case authConstants.ADD_BUSINESS_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authConstants.ADD_BUSINESS_FAILURE:
        return {
            ...state,
            error: action.error
        }
        default:
            return state
    }
}