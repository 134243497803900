import { notificationConstants } from "../constants";


export const notifications = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state
      }
    case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications
      }
    case notificationConstants.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.error
      } 
    default:
      return state;
  }
};
