import axios from "axios"
import { authHeader, config } from '../config'

export const dashboardService = {
    getDashboard,
}


async function getDashboard () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/order/v1/${process.env.REACT_APP_MERCHANT_NAME}/riders/my-analytics`, requestOptions)
    return response;
}