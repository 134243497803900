export const authConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    SEND_PASSWORD_REQUEST: 'SEND_PASSWORD_REQUEST',
    SEND_PASSWORD_SUCCESS: 'SEND_PASSWORD_SUCCESS',
    SEND_PASSWORD_FAILURE: 'SEND_PASSWORD_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    LOGOUT: 'LOGOUT',
}