import React from "react";
import { Row, Col, Image } from "antd";
import { Link,useParams } from "react-router-dom";
import LoginImage from "../components/LoginImage";

function RecoverPassword2Screen() {
  let {email} = useParams()
  return (
    <div>
    <Row type="flex" className="login-page">
        <Col type="flex" span={12} className="login-screen">
          <LoginImage />
        </Col>
        {/* Login Page */}
     

        <section className='w-full md:w-1/2'>


        <div className='min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen'>
            
            <div>
            <Image width={150} className="pb-2" src="./img/primhex-logo.png" />


            <div className='mt-32 flex justify-center'>
            <img
                alt=""
                className="mb-2 img"
                src={`${process.env.REACT_APP_IMAGE}/recover-password.png`}
              />
            </div>
            </div>


            <h1 className='mt-20 text-xl lg:text-3xl font-bold text-center'>
            Password Reset Instruction Sent
            </h1>

            <p className='text-gray-400 text-center text-md'>
                We have sent a password recovery instructions to your email
            </p>

                <Link to={`/rider/update-password/${email}`}>
                <button type='submit' style={{backgroundColor:'#006FC1'}} className='mt-16 w-full text-white py-1 rounded-sm font-bold'>
                    I have checked my mail box
                </button>
                </Link>


        </div>

        </section>
      </Row>
    </div>
  );
}

export default RecoverPassword2Screen;
