import {  profileConstants } from "../constants";


export const profiles = (state = {}, action) => {
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
      return {
        ...state
      }
    case profileConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: action.profiles
      }
    case profileConstants.GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.error
      } 
    case profileConstants.UPDATE_PROFILE_REQUEST:
    return {
        ...state
    }
    case profileConstants.UPDATE_PROFILE_SUCCESS:
    return {
        ...state,
        profiles: action.profiles
    }
    case profileConstants.UPDATE_PROFILE_FAIL:
    return {
        ...state,
        error: action.error
    } 
    case profileConstants.UPDATE_PASSWORD_REQUEST:
    return {
        ...state
    }
    case profileConstants.UPDATE_PASSWORD_SUCCESS:
    return {
        ...state,
        profiles: action.profiles
    }
    case profileConstants.UPDATE_PASSWORD_FAIL:
    return {
        ...state,
        error: action.error
    } 
    default:
      return state;
  }
};
