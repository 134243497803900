import React, { useState, useMemo, useEffect } from "react";
import { Input } from "antd";
import SideMenu from "../components/Sider";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Layout } from "antd";
import { profilesActions } from "../actions";
import Loader from "../components/Loader";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const { Content } = Layout;
const headerTitle = "Profile";

function ProfileScreen({ history }) {
  // const [full_name, setFull_name] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [loading2, setLoading2] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const profiles = useSelector((state) => state.profiles);

  useEffect(() => {
    dispatch(profilesActions.getProfile(user?.id));
    // eslint-disable-next-line
  }, [dispatch]);

  let profileInfo = profiles?.profiles?.data?.data;

  const [error, setError] = useState({
    current_password: "",
    password: "",
  });

  const [list, setList] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });

  const disabled = useMemo(
    () =>
      !list.current_password || !list.password || !list.password_confirmation,
    [list.current_password, list.password, list.password_confirmation]
  );

  const handle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [query, setQuery] = useState({});

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleChange = async (e) => {
    setView(true);

    e.preventDefault();
    setFile(e.target.files[0]?.name);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
    let image = dataURLtoFile(cropper?.url, file);

    setQuery((prevState) => ({
      ...prevState,
      profile_image: image,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    await setLoading(true);
    setError(null);
    // console.log("Success:", value);
    // console.log(email,password)

    const formdata = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formdata.append(key, value);
    });

    console.log(...formdata);

    const response = await dispatch(profilesActions.updateProfile(formdata));
    if (response.data.status === true) {
      setLoading(false);
      setQuery({
        profile_image: "",
      });
      toast.success(response?.data?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else {
      toast.error(response?.data?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setLoading(false);
    }
    dispatch(profilesActions.getProfile(user?.id));
  };

  const show = async (e) => {
    e.preventDefault();
    await setLoading2(true);
    // console.log("Success:", value);
    // console.log(email,password)

    const formdata = new FormData();
    Object.entries(list).forEach(([key, value]) => {
      formdata.append(key, value);
    });

    console.log(...formdata);

    const response = await dispatch(profilesActions.updatePassword(formdata));
    console.log(response?.data);
    if (response.data.status === true) {
      setLoading2(false);
      toast.success(response?.data?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });

      setList({
        current_password: "",
        password: "",
        password_confirmation: "",
      });
    } else {
      toast.error("Try again", {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setLoading2(false);
    }

    setError((x) => ({
      ...x,
      current_password: response?.data?.current_password,
      password: response?.data?.password,
    }));
  };

  return (
    <div>
      <Layout>
        <SideMenu />

        <Content style={{ marginLeft: 250 }} className="">
          <Header titles={headerTitle} />
          <div className="content-setting">
            <div className="dashboard-report pt-2 pb-1">
              <h2>Profile</h2>
              <p>Here are the latest report on your account</p>
            </div>

            {/* <Card className="profile-container" style={{ width: 600 }}> */}
            <form onSubmit={(e) => submit(e)}>
              <div className="profile-body">
                <div className="profile-body-content">
                  <h3>Change Profile Picture</h3>
                  <p>Choose a new avatar to be used across primhex</p>
                </div>
                <div className="profile-body-input flex flex-col items-end">
                  <div className="flex flex-col ml-auto items-end">
                    <div className="image-cropper">
                      <img
                        width={120}
                        alt=""
                        className="profile-pic"
                        src={profileInfo?.profile_image_url}
                      />
                    </div>

                    <div className="flex flex-row ml-auto mt-2">
                      <input
                        type={"file"}
                        className="file-btn items-end"
                        name="profile_image"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  {view && (
                    <button
                      type="button"
                      onClick={() => getCropData()}
                      className="bg-gray-200 mt-1 p-2 rounded-md"
                    >
                      Crop Image
                    </button>
                  )}

                  {view && (
                    <div className="w-full grid grid-cols-2 gap-4 justify-between mt-1">
                      {cropData !== "#" && (
                        <div>
                          <img
                            className="w-32 rounded-md shadow text-xs"
                            src={cropData}
                            alt="cropped"
                          />
                          <button
                            onClick={() => setView(false)}
                            className="bg-hex-blue hover:bg-blue-800 text-xs text-white mt-1 p-2 rounded-md"
                          >
                            Select Image
                          </button>
                        </div>
                      )}

                      <Cropper
                        // style={{ height: 250, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="profile-body">
                <div className="profile-body-content">
                  <h3>Full Name</h3>
                  <p>Customize your account name</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="Full Name"
                    name="full_name"
                    value={query.full_name}
                    onChange={(e) => handleInput(e)}
                  />
                </div>
              </div>
              <div className="profile-body">
                <div className="profile-body-content">
                  <h3>Phone Number</h3>
                  <p>Update your phone number</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="Phone Number"
                    name="phone"
                    value={query.phone}
                    onChange={(e) => handleInput(e)}
                  />
                </div>
              </div>
              <div className="profile-body">
                <div className="profile-body-content">
                  <h3>Email Address</h3>
                  <p>Update your email address</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="Email Address"
                    name="email"
                    value={query.email}
                    onChange={(e) => handleInput(e)}
                  />
                </div>
              </div>
              <button type="primary submit" className="profile-button">
                Update Profile
                {loading && <Loader />}
              </button>
            </form>

            <div className="dashboard-report pb-1 pt-3">
              <h2>Change Password</h2>
            </div>

            <form onSubmit={(e) => show(e)}>
              <div className="profile-body">
                <div className="profile-body-content">
                  <h3>Old Password</h3>
                  <p>Fill in your old password</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="Old Password"
                    type="text"
                    name="current_password"
                    className=""
                    value={list.current_password}
                    onChange={(e) => handle(e)}
                  />
                </div>
              </div>
              <div className="text-red-500 float-right text-xs">
                {error?.current_password}
              </div>

              <div className="profile-body mt-2">
                <div className="profile-body-content">
                  <h3>New Password</h3>
                  <p>Fill in your new password</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="New Password"
                    type="text"
                    name="password"
                    value={list.password}
                    onChange={(e) => handle(e)}
                  />
                </div>
              </div>

              <p className="text-red-500 float-right text-xs">
                {error?.password}
              </p>

              <div className="profile-body mt-2">
                <div className="profile-body-content">
                  <h3>Confirm New Password</h3>
                  <p>Confirm your new password</p>
                </div>
                <div className="profile-body-input">
                  <Input
                    placeholder="Confirm Password"
                    type="text"
                    name="password_confirmation"
                    value={list.password_confirmation}
                    onChange={(e) => handle(e)}
                  />
                </div>
              </div>
              <button
                type="primary submit"
                disabled={disabled}
                className={
                  disabled ? "profile-button-disabled" : "profile-button"
                }
              >
                Update Password
                {loading2 && <Loader />}
              </button>
            </form>

            {/* </Card> */}
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default ProfileScreen;
