import { orderConstants,orderListConstants } from "../constants"
import { orderService } from "../services/order.services"

export const ordersActions = {
    getOrders,
    getSorterOrders,
    getAcceptedOrders
}
export const ordersListActions = {
  getPendingOrders,
}



function getOrders () {
    return async dispatch => {
        dispatch(request())

        try {
          const orders = await orderService.getOrders()
          dispatch(success(orders))
          return orders;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request () { return { type: orderConstants.GET_ORDER_REQUEST } }
  function success (orders) { return { type: orderConstants.GET_ORDER_SUCCESS, orders } }
  function failure (error) { return { type: orderConstants.GET_ORDER_FAIL, error } }
}


function getAcceptedOrders () {
  return async dispatch => {
      dispatch(request())

      try {
        const orders = await orderService.getAcceptedOrders()
        dispatch(success(orders))
        return orders;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response;
        
    }
  }

function request () { return { type: orderConstants.GET_ACCEPTED_ORDER_REQUEST } }
function success (orders) { return { type: orderConstants.GET_ACCEPTED_ORDER_SUCCESS, orders } }
function failure (error) { return { type: orderConstants.GET_ACCEPTED_ORDER_FAIL, error } }
}

function getPendingOrders () {
  return async dispatch => {
      dispatch(request())

      try {
        const ordersList = await orderService.getPendingOrders()
        dispatch(success(ordersList))
        return ordersList;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response;
        
    }
  }

function request () { return { type: orderListConstants.GET_PENDING_ORDER_REQUEST } }
function success (ordersList) { return { type: orderListConstants.GET_PENDING_ORDER_SUCCESS, ordersList } }
function failure (error) { return { type: orderListConstants.GET_PENDING_ORDER_FAIL, error } }
}

function getSorterOrders (sort,no,page) {
  return async dispatch => {
      dispatch(request(sort,no,page))

      try {
        const orders = await orderService.getSorterOrders(sort,no,page)
        dispatch(success(orders))
        return orders;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response;
        
    }
  }

function request (sort,no,page) { return { type: orderConstants.GET_COMPLETED_ORDER_REQUEST,sort,no,page } }
function success (orders) { return { type: orderConstants.GET_COMPLETED_ORDER_SUCCESS, orders } }
function failure (error) { return { type: orderConstants.GET_COMPLETED_ORDER_FAIL, error } }
}
