import React from "react";
import { Col } from "antd";
// import { Link } from "react-router-dom";

function LoginImage() {
  return (
    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="login-screen">
      {/* <div className="login-screen"></div> */}
  </Col>
  );
}

export default LoginImage;
