import React from "react";
import Header from "./Header";
import SideMenu from "./Sider";
import { Layout, Card } from "antd";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Steps } from "antd";

const { Step } = Steps;

const headerTitle = "Send a Package";
const { Content } = Layout;

const EditRoute = () => {
  return (
    <div>
      <Layout>
        <SideMenu />
        <Content style={{ marginLeft: 250 }}>
          <Header titles={headerTitle} />
          <div className="content-setting">
            <div className="dashboard-report ord pt-2 pb-1">
              <div className="order-content">
                <h2>Monday Deliverables</h2>
                <p>Routes Details</p>
                <h6>3 Routes . 50km . 4hrs</h6>
              </div>
              <div className="order-button">
                <Link to="/rider/edit-route">
                  <Button type="primary" className="new-route">
                    Edit Route
                  </Button>
                </Link>
              </div>
            </div>
            <div className="transaction-card">
              <div>
                <Card className="profile-container create-route edit-route">
                  <Steps progressDot current={1} direction="vertical">
                    <Step
                      title="Block D12, Ajenifunja Str"
                      description="Ikorodu, Lagos"
                    />
                    <Step
                      title="Block D12, Ajenifunja Str"
                      description="Ikeja, Lagos"
                    />
                    <Step
                      title="Block D12, Ajenifunja Str"
                      description="Surulere, Lagos"
                    />
                    <Step
                      title="Block D12, Ajenifunja Str"
                      description="Festac, Lagos"
                    />
                  </Steps>
                </Card>
              </div>
              <div>
                <Card className="card-trans">
                  <div className="trans-header">
                    <div className="trans-link">
                      {/* <Link onClick={this.viewAll}>View All</Link> */}
                    </div>
                  </div>
                  <div className="main-content">
                    <img
                      alt=""
                      width={600}
                      className="pb-2"
                      src={`${process.env.REACT_APP_IMAGE}/map2.jpg`}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default EditRoute;
