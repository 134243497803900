import React, { useEffect,useState } from 'react'
import {
  InboxOutlined
} from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ordersActions } from '../actions/orderActions';


function TransactionDetail() {
  const [current, setCurrent] = useState(1)
  const orders = useSelector(state=>state.orders)
  const dispatch = useDispatch()
  let orderList = orders?.orders?.data?.data
  let orderRoutes = orders?.orders?.data?.meta

    useEffect(() => {
        dispatch(ordersActions.getSorterOrders('completed',5,1))
      }, [dispatch])

      const changePage = async (page) => {
        setCurrent(page)
        await dispatch(ordersActions.getSorterOrders('completed',5,page))
      }

    return (
        <div>
            {orderList?.length > 0 ? orderList?.map((x)=> (
                <div key={x.id} className="trans-content my-1">
                    <div className="main-content">
                        <div className="content-image">
                            <InboxOutlined style={{ fontSize: '24px' }} className="icon-package" />
                        </div>
                        <div className="content-main">
                            <h2>Delivery Payment | {x?.sender_info?.sender_name}</h2>
                            <p>{x?.pickup_time !== null ? x?.pickup_time : ''}</p>
                        </div>
                    </div>
                        <div className="content price">
                            <h2>&#8358; {x?.payment?.price}</h2>
                        </div>
            </div>
            )) : (
                <Empty />
            )}
            {orderList?.length > 0 && (<div className='float-right mt-2'>
                <Pagination current={current} total={orderRoutes?.total} pageSize={orderRoutes?.per_page} onChange={changePage} />
            </div>)}
        </div>
    )
}

export default TransactionDetail
