import React from "react";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component"
import {
  UserOutlined,
  ShopOutlined,
  NodeIndexOutlined,
  AppstoreOutlined,
  WalletOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import { Link,useHistory } from "react-router-dom";
import { authActions } from "../actions";
const { Sider } = Layout;

function SiderMenu() {
  const dispatch = useDispatch();
  const history = useHistory()
  const user = JSON.parse(sessionStorage.getItem('user'))

  const logoutHandler = () => {
    dispatch(authActions.logout())
        history.push('/rider')
    
  };

  return (
    <div className="sider-media">
      <Sider
        width={250}
        style={{
          paddingLeft: "55px",
          borderRight: "2px solid #F6F6F6",
          overflow: "auto",
          overflowX: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        breakpoint="lg"
        collapsedWidth="0"
        className="borddash"
      >
        <div className="companylogo">
          <img
            width={110}
            className=""
            alt="company-logo"
            src={`${process.env.REACT_APP_IMAGE}/company-logo.png`}
          />
          {/* <h3 className="login-logo">HEX LOGISTICS</h3> */}
        </div>

        <div className="profile-section">
          <img
            // width={80}
            alt=""
            className="mx-auto w-auto rounded-md"
            src={user?.profile_image_url !== '' ? user?.profile_image_url : `${process.env.PUBLIC_URL}/img/dpgirl.png`}
            // src={`${process.env.PUBLIC_URL}/img/dpgirl.png`}
          />
          <div className="icons-list">
             <ReactStars
                count={5}
                value={parseInt(user?.ratings)}
                size={24}
                activeColor="#ffd700"
              />
          </div>
        </div>
        <div className="w-95 rider py1">
          <Menu mode="inline">
            <Menu.Item key="1" icon={<AppstoreOutlined />} className="menus">
              <Link to="/rider/" className="space-modules">
                <span className="iconss">Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<ShopOutlined />} className="menus">
              <Link to="/rider/order" className="space-modules">
                <span className="iconss">Orders</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<NodeIndexOutlined />} className="menus">
              <Link to="/rider/route" className="space-modules">
                <span className="iconss">Routes</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="4" icon={<WalletOutlined />} className="menus">
              <Link to="/rider/history" className="space-modules">
                <span className="iconss">History</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="5" icon={<UserOutlined />} className="menus">
              <Link to="/rider/profile" className="space-modules">
                <span className="iconss">Profile</span>
              </Link>
            </Menu.Item>
          </Menu>
          {/* {userInfo ? (
            <div className="mdle logoutContainer">
              <button className="logout" onClick={logoutHandler}>
                <LogoutOutlined className="icon" />
                <span className="iconss"> Logout</span>
              </button>
            </div>
          ) : ( */}
            <div className="logoutContainer">
              <button className="logout" onClick={logoutHandler}>
                <LogoutOutlined className="icon" />
                <span className="iconss"> Logout</span>
              </button>
            </div>
          {/* )} */}
        </div>
      </Sider>
    </div>
  );
}

export default SiderMenu;
