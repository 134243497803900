import axios from "axios"
import { config } from '../config'

export const authService = {
    login,
    logout,
    register,
    sendPassword,
    updatePassword
}

async function login (email,password) {
    const requestOptions = {
        headers: {"Accept":"application/json"}
    }
    const type = "rider";
    const platform = "web";
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/login?type=${type}&platform=${platform}`, {email,password}, requestOptions)
    const auth = response.data

    if(auth.status === true){
        sessionStorage.setItem('token', JSON.stringify(auth?.data?.token))
        sessionStorage.setItem('user',JSON.stringify(auth?.data))
        sessionStorage.setItem('isAuthenticated',true)
    }
    else {
        
    }
    return response;
}

async function sendPassword (formdata) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    }
    const type = "rider";
    const platform = "email";
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/password/reset-request?via=${platform}&type=${type}`, formdata ,requestOptions)
    return response;
}

async function updatePassword (formdata) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    }
    const platform = "email";
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/password/reset?type=${platform}`, formdata ,requestOptions)
    return response;
}


async function register (formdata) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/v1/${process.env.REACT_APP_MERCHANT_NAME}/signup?type=rider&platform=web`, formdata ,requestOptions)
    return response;
}

async function logout () {
    
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('isAuthenticated')
  
    console.log('Logged out Successfully');
  }
