import { Row } from "antd";
import React from "react";
// import React from "react";
import LoginImage from "../components/LoginImage";
import { Col, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { checkOtp } from "../components/funtionsHelpers";
// import { useDispatch } from "react-redux";
// import { otp } from "../actions/userActions"
// import Message from "../components/Message"
// import Loader from "../components/Loader";

const VerifyOTP = ({ location, history }) => {
  // const [message] = useState(null);
  const otpRef = React.createRef();

  const onFinish = async (values) => {
    // console.log( details, otp_verification, newOtpInfo, user, requestNewPassword )
    //   this.props;

    const { otpA, otpB, otpC, otpD, otpE, otpF } = values;
    const otpNo = `${otpA}${otpB}${otpC}${otpD}${otpE}${otpF}`;
    console.log(otpNo);
    
  };
  const callback = () => {
    const { newOtpInfo, resend_otp, user } = this.props;
    const { phone } = user;
    let time = 5 * 60 * 1000;
    let details = { phone: phone };
    this.otpRef.current.resetFields();
    setTimeout(() => {
      resend_otp(details);
    }, time);
    if (newOtpInfo && newOtpInfo.status) {
      this.setState({
        resendOtp: true,
      });
    }
  };
  const verifyOtp = () => {
    const { otpVerification, details, history } = this.props;
    checkOtp(details.type, otpVerification, history, callback);
  };
  console.log(verifyOtp);

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  return (
    <div>
     <Row type="flex" className="login-page">
        <Col type="flex" span={12} className="login-screen">
          <LoginImage />
        </Col>


        <Col>
          <div className="min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen">
            <div className="pb-2 ">
            <img
              alt="register-logo"
              width={150}
              src={`${process.env.REACT_APP_IMAGE}/hex-logo-icon.png`}
            />
            <h3 className="verity-otp-text">HEX LOGISTICS</h3>
            </div>
            <h2>Verify Account</h2>
            <p>A code has been sent your phone number</p>
            <Form name="otp" className="pt4" onFinish={onFinish} ref={otpRef}>
              <div className="mds">
                <Form.Item
                  name="otpA"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="otpB"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="otpC"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="otpD"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="otpE"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="5"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
                <Form.Item
                  name="otpF"
                  className="inputn"
                  rules={[
                    {
                      message: "",
                      required: true,
                      pattern: new RegExp(/\d{1}$/),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    tabIndex="6"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </Form.Item>
              </div>
              <Form.Item className="pt4" shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  const { otpA, otpB, otpC, otpD, otpE, otpF } =
                    getFieldsValue();
                  getFieldsError();
                  const formIsComplete =
                    !!otpA && !!otpB && !!otpC && !!otpD && !!otpE && !!otpF;
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="regbtn"
                      disabled={
                        !formIsComplete ||
                        !!getFieldsError().filter(({ errors }) => errors.length)
                          .length
                      }
                      block
                    >
                      Verify
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
            <h4 className="text-center mt-1">
              You don't have an account? &nbsp;
              <Link to="/rider/register" className="login-register-route">
                Register for one now
              </Link>
            </h4>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyOTP;
