import "./App.css";
import "./Responsive.css";
import { BrowserRouter as Router, Route ,Switch} from "react-router-dom";
import Login from "./screens/LoginScreen";
import Dashboard from "./screens/DashboardScreen";
import Orders from "./screens/OrderScreen";
import History from "./screens/HistoryScreen";
import Profile from "./screens/ProfileScreen";
import RouteScreen from "./screens/RouteScreen";
import RequestSentScreen from "./screens/RequestSentScreen";
import RecoverPassword1Screen from "./screens/RecoverPassword1Screen";
import RecoverPassword2Screen from "./screens/RecoverPassword2Screen";
import UpdatePasswordScreen from "./screens/UpdatePasswordScreen";
import CreateRoute from "./components/createRoute";
import EditRoute from "./components/editRoute";
import VerifyOTP from "./screens/VerifyOTPScreen"
import PrivateRoute from './routing'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegisterRider from "./screens/RegisterRider";
import {Helmet} from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <title>{`${process.env.REACT_APP_NAME} Rider App`}</title>
        <link
          rel="icon"
          type="image/png"
          href={`${process.env.REACT_APP_IMAGE}/logo192.png`}
          sizes="16x16"
        />
      </Helmet>
      <Router>
      <ToastContainer />
        <Switch>
            <Route exact path="/rider/" component={Login} />
            <Route path="/rider/register" component={RegisterRider} />
            <Route
              path="/rider/recover-password"
              component={RecoverPassword1Screen}
            />
            <Route
              path="/rider/recover-password2/:email"
              component={RecoverPassword2Screen}
            />
            <Route path="/rider/update-password/:email" component={UpdatePasswordScreen} />
            <Route path="/rider/verify-otp" component={VerifyOTP} />
            <PrivateRoute path="/rider/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/rider/order" component={Orders} />
            <PrivateRoute path="/rider/route" component={RouteScreen} />
            <PrivateRoute path="/rider/create-route" component={CreateRoute} />
            <PrivateRoute path="/rider/edit-route" component={EditRoute} />
            <PrivateRoute path="/rider/history" component={History} />
            <PrivateRoute path="/rider/profile" component={Profile} />
            <PrivateRoute path="/rider/success" component={RequestSentScreen} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
