import React,{ useEffect } from "react";
// import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { Table } from "antd";
import { Layout } from "antd";
// import { Link } from "react-router-dom";
import SideMenu from "../components/Sider";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { ordersActions } from "../actions/orderActions";
import Spinner from "../components/Spinner";

const { Content } = Layout;
const headerTitle = "Route";

const columns = [
  {
    title: "ROUTE NAME",
    dataIndex: "name",
    key: "name",
  },
  // {
  //   title: "ROUTES",
  //   dataIndex: "routes",
  //   key: "routes",
  // },
  {
    title: "PRICE",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: status => 
        (<div className={`${status === 'in_transit' ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 'pending' ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 'rider_arrived' ? 'bg-success flex justify-center items-center text-black  rounded-sm text-xs' : status === 'incomplete' ? 'bg-fail flex justify-center items-center text-black  rounded-sm text-xs' : 'flex justify-center items-center text-black  rounded-sm text-xs'}`}>
            {status === 'in_transit' ? 'In-Progress' : status === 'pending' ? 'Pending' :  status === 'rider_arrived' ? 'Rider Arrived' : status === 'incomplete' ? 'Failed' : ''}
        </div> )
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  // {
  //   title: "ACTION",
  //   dataIndex: "view",
  //   key: "view",
  // },
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const RouteScreen = () => {
  const dispatch = useDispatch()
  const orders = useSelector(state=>state.orders)

  useEffect(() => {
    dispatch(ordersActions.getAcceptedOrders())
  }, [dispatch])
  let routeList = orders?.orders?.data
  console.log(routeList)

  const dataSource = routeList?.length > 0 ? routeList?.map((item,i) => ({
    id: item?.id,
    key: item?.id,
    SN: ++i,
    name: item?.pickup_address,
    price: parseFloat(item?.price?.replace(',','')).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    }),
    status: item?.status,
    order: item?.delivery_type?.name,
    date: item?.created_at
})) : ''

  return (
    <div>
      
      <Layout>
        <SideMenu />
        <Content style={{ marginLeft: 250 }}>
          <Header titles={headerTitle} />
          {routeList?.length > 0 ? (
          <div className="content-setting">
            <div className="dashboard-report ord pt-2">
              <div className="order-content">
                <h2>All Routes</h2>
                <p>Here are the latest report on your account</p>
              </div>
              {/* <div className="order-button">
                <Link to="/rider/create-route">
                  <Button type="primary" className="new-route">
                    New Route
                  </Button>
                </Link>
              </div> */}
            </div>

            {/* Tabs */}
            <div className="table-wrapper">
              {/* <div className="tab-header">
                <Button
                  size="large"
                  icon={<FilterFilled className="button-icon" />}
                >
                  Filter
                </Button>
                <Input
                  className="table-search"
                  size="large"
                  style={{ width: "400px", backgroundColor: "#f9f9f9" }}
                  placeholder="Search Users by Name, Email or Date"
                  prefix={<SearchOutlined className="search-icon" />}
                />
              </div> */}
              <Table
                rowSelection={rowSelection}
                dataSource={dataSource}
                columns={columns}
                className="table-content"
              />
              ;
            </div>
            {/* <Tabs className="color-tab-text" defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Pending" key="1">
                  
                </TabPane>
                <TabPane tab="Accepted" key="2">
                    <Table dataSource={dataSource} columns={columns} />
                </TabPane>
            </Tabs> */}
          </div>) : (<Spinner />)}
        </Content>
      </Layout>
    </div>
  );
};

export default RouteScreen;
