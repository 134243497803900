import React, { useEffect } from "react";
// import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { Tabs, Table } from "antd";
import { Layout } from "antd";
import SideMenu from "../components/Sider";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { ordersActions, ordersListActions } from "../actions/orderActions";

const { Content } = Layout;
const headerTitle = "Order";

const { TabPane } = Tabs;

const columns = [
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "ORDER TYPE",
    dataIndex: "order",
    key: "order",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  // {
  //   title: "ACTION",
  //   dataIndex: "view",
  //   key: "view",
  // },
];

const columns2 = [
  // {
  //   title: "NAME",
  //   dataIndex: "name",
  //   key: "name",
  // },
  {
    title: "Sender Name",
    dataIndex: "sender_name",
    key: "sender_name",
  },
  {
    title: "Receiver Name",
    dataIndex: "receiver_name",
    key: "receiver_name",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  // {
  //   title: "ACTION",
  //   dataIndex: "view",
  //   key: "view",
  // },
];


// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const OrderScreen = () => {
  const dispatch = useDispatch()
  const orders = useSelector(state=>state.orders)
  const ordersList = useSelector(state=>state.ordersList)
  
  useEffect(() => {
    dispatch(ordersListActions.getPendingOrders())
  }, [dispatch])

  const orderList = orders?.orders?.data
  const orderPendingList = ordersList?.ordersList?.data
  console.log(orderList)


  function callback(key) {
    console.log(key);
    if(key === 'completed'){
    dispatch(ordersActions.getSorterOrders(key,10,1))
    }
    else{
    dispatch(ordersListActions.getPendingOrders())
    }
  }

  const dataSources = orderPendingList?.data?.map((item,i) => ({
    id: item.id,
    key: item?.id,
    SN: ++i,
    sender_name: item?.sender_name,
    receiver_name: item?.receiver_name,
    price: parseFloat(item?.payment?.price?.replace(',','')).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    }),
    date: item?.created_at?.split('T')[0]
}))
  
  const dataSource = orderList?.data !== '' ?  orderList?.data?.map((item,i) => ({
    id: item.id,
    key: item?.id,
    SN: ++i,
    name: item?.receiver_info?.receiver_name,
    phoneNumber: item?.receiver_info?.receiver_phone_number,
    address: item?.destination_address,
    order: item?.delivery_type?.name,
    date: item?.created_at?.split('T')[0]
})) : [] 


  return (
    <div>
      <Layout>
        <SideMenu />
        <Content className="dashboard-content">
          <Header titles={headerTitle} />
          <div className="content-setting">
            <div className="dashboard-report ord  pt-2">
              <div className="order-content">
                <h2>All Orders</h2>
                <p>Here are the latest report on your account</p>
              </div>
            </div>

            {/* Tabs */}
            <Tabs
              className="color-tab-text"
              defaultActiveKey="1"
              onChange={callback}
            >
              <TabPane tab="Pending" key="pending">
                <div className="table-wrapper">
                  {/* <div className="tab-header">
                    <Button
                      size="large"
                      icon={<FilterFilled className="button-icon" />}
                    >
                      Filter
                    </Button>
                    <Input
                      className="table-search"
                      size="large"
                      style={{  backgroundColor: "#f9f9f9" }}
                      placeholder="Search Users by Name, Email or Date"
                      prefix={<SearchOutlined className="search-icon" />}
                    />
                  </div> */}
                  <Table
                    rowSelection={rowSelection}
                    dataSource={dataSources}
                    columns={columns2}
                    className="table-content"
                  />
                </div>
              </TabPane>
              <TabPane tab="Completed" key="completed">
                <div className="table-wrapper">
                  {/* <div className="tab-header">
                    <Button
                      size="large"
                      icon={<FilterFilled className="button-icon" />}
                    >
                      Filter
                    </Button>
                    <Input
                      className="table-search"
                      size="large"
                      style={{ width: "400px", backgroundColor: "#f9f9f9" }}
                      placeholder="Search Users by Name, Email or Date"
                      prefix={<SearchOutlined className="search-icon" />}
                    />
                  </div> */}
                  <Table
                    rowSelection={rowSelection}
                    dataSource={dataSource}
                    columns={columns}
                    className="table-content"
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default OrderScreen;
