import { combineReducers } from 'redux'
import { auth } from './authReducer'
import { dashboard } from './dashboardReducer'
import { orders, ordersList } from './orderReducer'
import { profiles } from './profileReducer'
import { notifications } from './notificationReducer'


const rootReducer = combineReducers({
    auth,
    dashboard,
    orders,
    profiles,
    notifications,
    ordersList
})

export default rootReducer