import { authConstants } from "../constants";
import { authService } from "../services/auth.services";

export const authActions = {
    login,
    logout,
    sendPassword,
    updatePassword,
    addBusiness,
    register
}
function login (email,password) {
    return async dispatch => {
        dispatch(request(email,password))

        try {
          const auth = await authService.login(email,password)
          dispatch(success(auth))
          return auth;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
      }
    }

  function request (email,password) { return { type: authConstants.LOGIN_REQUEST, email,password } }
  function success (auth) { return { type: authConstants.LOGIN_SUCCESS, auth } }
  function failure (error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function sendPassword (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.sendPassword(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authConstants.SEND_PASSWORD_REQUEST, formdata } }
function success (auth) { return { type: authConstants.SEND_PASSWORD_SUCCESS, auth } }
function failure (error) { return { type: authConstants.SEND_PASSWORD_FAILURE, error } }
}


function register (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.register(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authConstants.REGISTER_REQUEST, formdata } }
function success (auth) { return { type:authConstants.REGISTER_SUCCESS, auth } }
function failure (error) { return { type:authConstants.REGISTER_FAILURE, error } }
}


function addBusiness (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.addBusiness(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authConstants.ADD_BUSINESS_REQUEST, formdata } }
function success (auth) { return { type: authConstants.ADD_BUSINESS_SUCCESS, auth } }
function failure (error) { return { type: authConstants.ADD_BUSINESS_FAILURE, error } }
}

function updatePassword (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.updatePassword(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
    }
  }

function request (formdata) { return { type: authConstants.UPDATE_PASSWORD_REQUEST, formdata } }
function success (auth) { return { type: authConstants.UPDATE_PASSWORD_SUCCESS, auth } }
function failure (error) { return { type: authConstants.UPDATE_PASSWORD_FAILURE, error } }
}


function logout(){
    return dispatch => {
  
      dispatch(success())
      authService.logout().then(
        // history.push('/rider')
            // document.location.href = "/rider"
      )
    }
  
    function success() { return { type: authConstants.LOGOUT} }
  }