import axios from "axios"
import { authHeader, config } from '../config'

export const orderService = {
    getOrders,
    getAcceptedOrders,
    getPendingOrders,
    getSorterOrders
}


async function getOrders () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/order/v1/${process.env.REACT_APP_MERCHANT_NAME}/orders/my-orders`, requestOptions)
    return response;
}

async function getAcceptedOrders () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/order/v1/${process.env.REACT_APP_MERCHANT_NAME}/riders/accept-order?sdate=`, requestOptions)
    return response;
}

async function getPendingOrders () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/order/v1/${process.env.REACT_APP_MERCHANT_NAME}/orders/my-orders?s=pending&rows=10&page=1`, requestOptions)
    console.log(response);
    return response;
    
}



async function getSorterOrders (sort,no,page) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/order/v1/${process.env.REACT_APP_MERCHANT_NAME}/riders/order-history?status=${sort}&rows=${no}&page=${page}`, requestOptions)
    return response;
}

