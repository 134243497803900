import { notificationConstants } from "../constants"
import { notificationService } from "../services"

export const notificationsActions = {
    getNotification
}

function getNotification (id) {
    return async dispatch => {
        dispatch(request(id))

        try {
          const notifications = await notificationService.getNotifications(id)
          dispatch(success(notifications))
          return notifications;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request (id) { return { type: notificationConstants.GET_NOTIFICATIONS_REQUEST, id } }
  function success (notifications) { return { type: notificationConstants.GET_NOTIFICATIONS_SUCCESS, notifications } }
  function failure (error) { return { type: notificationConstants.GET_NOTIFICATIONS_FAIL, error } }
}
