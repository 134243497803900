import React, { useState,useMemo } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import LoginImage from "../components/LoginImage";
import { useDispatch } from "react-redux";
// import Message from "../components/Message";
import Spin from "../components/Loader";
// import { login } from "../actions/userActions";
import { authActions } from "../actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'


const LoginScreen = ({ location, history }) => {
  const auth = sessionStorage.getItem('isAuthenticated')
  const [loading,setLoading] = useState(false)
  const [passwords,showPassword] = useState(false)
  const dispatch = useDispatch();

  
  const [query, setQuery] = useState({
      email: '',
      password: '',
    })

  const disabled = useMemo(
    () =>  !query.email || !query.password,
    [ query.email, query.password]
    );

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };


  const submit = async (e) => {
    e.preventDefault()
    await setLoading(true)

    const response = await dispatch(authActions.login(query.email,query.password))
    console.log(response)
    if(response.data.status === true) {
      setLoading(false) 
    toast.success('Welcome...',{
      autoClose: 2000,
      hideProgressBar: true})
  }
  else {
      toast.error(response?.data?.message,{
        autoClose: 2000,
        hideProgressBar: true })
        setLoading(false) 
      }
  }

  // console.log(auth)


  if (auth){
    return <Redirect to='/rider/dashboard'/>
  }

  return (
    <div>
      <Row className="login-page">
      

      <Col type="flex" span={12} className="login-screen">
          <LoginImage />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} span={12} type="flex">
          <div className="space-login-wrapper min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen">
            {/* Logo Icon */}
            <div className="company-details">
            <img
              width={100}
              alt=""
              src={`${process.env.REACT_APP_IMAGE}/company-logo.png`}
            />
            <h3 className="login-logo">HEX LOGISTICS</h3>
            </div>
            <h2 className="">Login into your Account</h2>
            <h4>Fill in your valid credentials to continue</h4>

            <form className='mt-12' onSubmit={(e)=>submit(e)}>

                  <div>
                      <input type='text' placeholder = 'Email Address' className='login-text-box' value={query.email} name='email' onChange={(e)=>handleInput(e)} />
                  </div>
                      
                  <div className='mt-12 login-text-box flex bg-white flex-row item-center justify-between'>
                      <input type={passwords ? 'text' : 'password'} placeholder = 'Password' className='flex-1 outline-none' value={query.password} name='password' onChange={(e)=>handleInput(e)}/>
                      <button type='button' className='focus:outline-none' onClick={()=>showPassword(!passwords)}>
                          {passwords ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                      </button>
                  </div>


                  <div className='mt-16'>
                      <Link to='/rider/recover-password' className='text-gray-700 text-xs hover:underline hover:text-black'>Forgot Password?</Link>
                  </div>

                  <button type='submit' disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-1 rounded-sm font-bold justify-center' : 'mt-12 w-full bg-hex-blue text-white rounded-sm font-bold flex items-center justify-center py-1'}>
                          Login into Account {loading &&  <Spin />}
                  </button>

                  </form>

            <h4 className="text-center mt-8">
              Don't have an account? &nbsp;
              <Link to="/rider/register" className="login-register-route">
                Register for one now
              </Link>
            </h4>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginScreen;
