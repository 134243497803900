import React, { useEffect } from "react";
import { BellOutlined, MenuOutlined } from "@ant-design/icons";
import { Dropdown, Empty, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { notificationsActions } from "../actions";

function Header(props) {
  const dispatch = useDispatch()
  const notifications = useSelector(state=>state.notifications)
  const user = JSON.parse(sessionStorage.getItem('user'))
  

  useEffect(() => {
    dispatch(notificationsActions.getNotification(user?.id))
    // eslint-disable-next-line
  }, [dispatch])

  let notification = notifications?.notifications?.data?.data

  const notificationList = ()=> {
  
    return(
    <>
      <Menu>
      
    {notification?.length > 0 ? notification?.map((item)=>(
      <>
      <div className="p-2 border-b border-gray-200">
          <h1 className="mt-2 font-bold">{item.title}</h1>
           <p className="mt-6">{item.log}</p>
      </div>
      </>
    )) : (
        <div className="p-2 border border-gray-200 rounded-md">
             <div className='p-2'>
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No new notifications'} />
             </div>
      </div>
    )}
      </Menu>
    </>
  )
}
  return (
    <div>
      <div className="screen-header">
        <div className="page-name">
          <h2>{props.titles}</h2>
        </div>
        <div className="page-notification">
          <Dropdown overlay={notificationList} placement="bottomLeft">
            <div className="color-icon">
              <BellOutlined
                  className="ic"
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#f0f0f0",
                  }}
                />     
            </div>
          </Dropdown>
        </div>
        <div className="menu-media">
          <MenuOutlined />
        </div>
      </div>
    </div>
  );
}

export default Header;
