export const profileConstants = {
           
    GET_PROFILE_REQUEST : "GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS : "GET_PROFILE_SUCCESS",
    GET_PROFILE_FAIL : 'GET_PROFILE_FAIL',

    UPDATE_PROFILE_REQUEST : "UPDATE_PROFILE_REQUEST",
    UPDATE_PROFILE_SUCCESS : "UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_FAIL : 'UPDATE_PROFILE_FAIL',

    UPDATE_PASSWORD_REQUEST : "UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS : "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAIL : 'UPDATE_PASSWORD_FAIL',

}