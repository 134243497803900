import React,{useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { dashboardActions } from "../actions";
// import { Icon } from "@iconify/react";

const Wallet = () => {
  const dispatch = useDispatch()
  const dashboard = useSelector(state=>state.dashboard)


  useEffect(() => {
    dispatch(dashboardActions.getDashboard())
  }, [dispatch])


  let dashboardData = dashboard?.dashboard?.data['0']

  console.log(dashboardData)
  return (
    <div>
      <div className="relative pb-2">
        <img src={`${process.env.REACT_APP_IMAGE}/historyBackground.png`} alt="wallet" />
        <div className="absolute">
          <h3 className="history-amount">History Amount</h3>
          <h2 className="history-figure">
            <span>{parseFloat(dashboardData?.total_accepted_order_amount?.replace(',','')).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NGN',
                  })}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
