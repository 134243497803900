import { dashboardConstants } from "../constants";


export const dashboard = (state = {}, action) => {
  switch (action.type) {
    case dashboardConstants.DASHBOARD_DETAILS_REQUEST:
      return {
        ...state
      }
    case dashboardConstants.DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        dashboard: action.dashboard
      }
    case dashboardConstants.DASHBOARD_DETAILS_FAIL:
      return {
        ...state,
        error: action.error
      } 
    default:
      return state;
  }
};
