import { profileConstants } from "../constants"
import { profileService } from "../services/profile.services"

export const profilesActions = {
    getProfile,
    updateProfile,
    updatePassword
}


function getProfile (id) {
    return async dispatch => {
        dispatch(request(id))

        try {
          const profiles = await profileService.getProfile(id)
          dispatch(success(profiles))
          return profiles;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request (id) { return { type: profileConstants.GET_PROFILE_REQUEST,id } }
  function success (profiles) { return { type: profileConstants.GET_PROFILE_SUCCESS, profiles } }
  function failure (error) { return { type: profileConstants.GET_PROFILE_FAIL, error } }
}

function updateProfile (formdata) {
    return async dispatch => {
        dispatch(request(formdata))

        try {
          const profiles = await profileService.updateProfile(formdata)
          dispatch(success(profiles))
          return profiles;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request (formdata) { return { type: profileConstants.UPDATE_PROFILE_REQUEST, formdata } }
  function success (profiles) { return { type: profileConstants.UPDATE_PROFILE_SUCCESS, profiles } }
  function failure (error) { return { type: profileConstants.UPDATE_PROFILE_FAIL, error } }
}


function updatePassword (formdata) {
    return async dispatch => {
        dispatch(request(formdata))

        try {
          const profiles = await profileService.updatePassword(formdata)
          dispatch(success(profiles))
          return profiles;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
          
      }
    }

  function request (formdata) { return { type: profileConstants.UPDATE_PASSWORD_REQUEST,formdata } }
  function success (profiles) { return { type: profileConstants.UPDATE_PASSWORD_SUCCESS, profiles } }
  function failure (error) { return { type: profileConstants.UPDATE_PASSWORD_FAIL, error } }
}