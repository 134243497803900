import axios from "axios"
import { authHeader, config } from '../config'

export const notificationService = {
    getNotifications
}

async function getNotifications (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/notification/api/notification?user_id=${id}`, requestOptions)
    return response;
}