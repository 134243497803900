export const orderConstants = {
           
            GET_ORDER_REQUEST : "GET_ORDER_REQUEST",
            GET_ORDER_SUCCESS : "GET_ORDER_SUCCESS",
            GET_ORDER_FAIL : 'GET_ORDER_FAIL',

            GET_ACCEPTED_ORDER_REQUEST : "GET_ACCEPTED_ORDER_REQUEST",
            GET_ACCEPTED_ORDER_SUCCESS : "GET_ACCEPTED_ORDER_SUCCESS",
            GET_ACCEPTED_ORDER_FAIL : 'GET_ACCEPTED_ORDER_FAIL',

            GET_COMPLETED_ORDER_REQUEST : "GET_COMPLETED_ORDER_REQUEST",
            GET_COMPLETED_ORDER_SUCCESS : "GET_COMPLETED_ORDER_SUCCESS",
            GET_COMPLETED_ORDER_FAIL : 'GET_COMPLETED_ORDER_FAIL'

}

export const orderListConstants = {
    GET_PENDING_ORDER_REQUEST : "GET_PENDING_ORDER_REQUEST",
    GET_PENDING_ORDER_SUCCESS : "GET_PENDING_ORDER_SUCCESS",
    GET_PENDING_ORDER_FAIL : 'GET_PENDING_ORDER_FAIL',
}
