import { notification } from "antd";
import { ExclamationOutlined } from "@ant-design/icons";

// give notification
export const messageNotification = (info) => {
  let email = info.email ? info.email : "";
  let phone = info.phone ? info.phone : "";
  let message = info.message ? info.message : "";
  notification.open({
    message: `${message}`,
    description: (
      <div className="small">
        <p>{email} </p> <p>{phone}</p>
      </div>
    ),
    icon: <ExclamationOutlined className="exc" />,
    className: "notify",
  });
};
// check otp
export const checkOtp = (type, otpVerification, history, callback) => {
  if (otpVerification.status === true) {
    if (type === "onboard_new_user") {
      history.push("/success");
    } else if (type === "reset_password") {
      history.push("/update_password");
    }
  } else {
    messageNotification(otpVerification);
    callback();
  }
};
