import React from "react";
import { Row, Col, Button, Image } from "antd";
import { Link } from "react-router-dom";
import LoginImage from "../components/LoginImage";

function RequestSentScreen(location, history) {
  return (
    <div>
      <Row sm={12} md={12} lg={12} xl={12}>
        <Col className="login-screen" span={12}>
          <LoginImage />
        </Col>
        {/* Login Page */}
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          span={12}
          className=" pb-4 align-content"
        >
          <div className="login-form-wrapper">
            {/* Logo Icon */}
            <img
              alt=""
              width={150}
              className="pb-2 mt-2"
              src={`${process.env.REACT_APP_IMAGE}/primhex-logo.png`}
            />
            <div className="request-screen">
              <Image className="mb-2 img" src="./img/successful.png" />
              <h2>Request sent</h2>
              <p>
                Your account request has been sent. A confirmation mail will be
                sent to your mail box once it has been approved
              </p>

              <Link to="/rider/login" className="">
                <Button
                  key="2"
                  block
                  className="submit-button"
                  htmlType="submit"
                >
                  Login into Account
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RequestSentScreen;
